// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import * as envSelector from './selectors/env'

const env = {
  appRoot: envSelector.selectApplicationRootPath(),
  assetsRoot: envSelector.selectAssetsRootPath(),
  config: {
    stack: {
      as: envSelector.selectAsConfig(),
      gs: envSelector.selectGsConfig(),
      is: envSelector.selectIsConfig(),
      js: envSelector.selectJsConfig(),
      ns: envSelector.selectNsConfig(),
    },
    language: envSelector.selectLanguageConfig(),
    supportLink: envSelector.selectSupportLinkConfig(),
    documentationBaseUrl: envSelector.selectDocumentationUrlConfig(),
  },
  pageData: envSelector.selectPageData(),
  sentryDsn: envSelector.selectSentryDsnConfig(),
  csrfToken: envSelector.selectCSRFToken(),
  siteName: envSelector.selectApplicationSiteName(),
  siteTitle: envSelector.selectApplicationSiteTitle(),
  siteSubTitle: envSelector.selectApplicationSiteSubTitle(),
  devEUIConfig: envSelector.selectDevEUIConfig(),
}

export default env
